const example4In1Config = 
`#  Lab Zeppelin
#  1234 Black Dog Rd
#  Misty Mountain CA, 54321
    
    <Report-Number>  Example-4-1
    
     <Restraint-ID>  055
    <Unc-Restraint>  0.034
    
@SERIES
             <Date>  09 13 2020
    <Technician-ID>  019
       <Balance-ID>  003
    
  <Direct-Readings>  1
<Direct-Reading-SF>  1.0027
         <Check-ID>  054
            <Grams>  1

         <Position>  A1kg     1000 7.9436 0.000045
         <Position>  B1kg     1000 7.9926 0.000045  3.961
         <Position>  C1kg     1000 7.9435 0.000045
         <Position>  D1kg     1000 7.9926 0.000045  6.353

        <Design-ID>  112
           <Design>  1 -1  0  0
           <Design>  1  0 -1  0
           <Design>  1  0  0 -1
           <Design>  0  1 -1  0
           <Design>  0  1  0 -1
           <Design>  0  0  1 -1
           <Design>  1 -1  0  0
           <Design>  1  0 -1  0
           <Design>  1  0  0 -1
           <Design>  0  1 -1  0
           <Design>  0  1  0 -1
           <Design>  0  0  1 -1

        <Restraint>  0 1 0 1
   <Check-Standard>  0 -1 0 1
        <Pass-Down>  0 0 0 0

          <Sigma-w>  0.0013
          <Sigma-b>  0.0019

   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96
   <Environmentals>  20.80 732.86 48.96

  <Env-Corrections>  -0.3 0.9 0.7

  <Balance-Reading>  6.7570
  <Balance-Reading>  -0.0018	
  <Balance-Reading>  9.1448	
  <Balance-Reading>  -6.7572	
  <Balance-Reading>  2.3888	
  <Balance-Reading>  9.1465	
  <Balance-Reading>  6.7574	
  <Balance-Reading>  -0.0010	
  <Balance-Reading>  9.1446	
  <Balance-Reading>  -6.7592	
  <Balance-Reading>  2.3887	
  <Balance-Reading>  9.1477
`

export default example4In1Config;